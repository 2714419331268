// Initialisation de la carte
var map = L.map('map').setView([48.0917593, -3.1383911], 7.50);
var isSearching = false;

// Ajout de la couche de tuiles OpenStreetMap
L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '© OpenStreetMap contributors'
}).addTo(map);

// Création d'un groupe de clusters
var markerClusterGroup = L.markerClusterGroup({
    iconCreateFunction: function (cluster) {
        return L.divIcon({
            html: '<div class="cluster-icon" >' + cluster.getChildCount() + '</div>',
            className: 'custom-cluster-icon',
            iconSize: [40, 40]
        });
    }
});
var customIcon = L.icon({
    iconUrl: 'https://api.getwemap.com/images/pps-categories/users/575193d7b19b1c9f98f3feff.02888672.png?width=80&height=80', // Chemin vers l'image de l'icône
    iconSize: [40, 40], // Taille de l'icône [largeur, hauteur]
    iconAnchor: [20, 40], // Point d'ancrage de l'icône [x, y] (le point en bas au centre)
    popupAnchor: [0, -40] // Point d'ancrage de la fenêtre contextuelle [x, y] (le point en haut au centre)
});

// Ajout des marqueurs à la couche de clusters
for (var i = 0; i < points.length; i++) {
    var point = points[i];
    var marker = L.marker([point.lat, point.lng], {
        icon: customIcon,
        title: point.name,
        date: point.date,
        lien_fiche_projet_ou_site_web: point.lien_fiche_projet_ou_site_web,
        taux: point.taux,
        media: point.media,
        ville: point.ville,
        porteur: point.porteur,
        participation: point.participation,
        sous_titre: point.sous_titre,
        adresse: point.adresse,
        programme: point.programme,
        thematique: point.thematique,
        cout: point.cout,
        lat: point.lat,
        description: point.description,
        lng: point.lng,
        cp: point.cp,
        code_insee: point.insee,
    });
    marker.bindPopup(point.name);
    markerClusterGroup.addLayer(marker);
    addPointToListMarkerAlinit(point, marker);
}
map.addLayer(markerClusterGroup);

function addPointToListMarkerAlinit(pointObject, marker) {
    var listItem = document.createElement('li');
    listItem.className = 'bzh-sidebar-cell';

    listItem.innerHTML = '<div class="ContentContainer--14f67fb doPXxe"><img src="https://api.getwemap.com/images/pps-categories/users/575193d7b19b1c9f98f3feff.02888672.png?width=80&amp;height=80" class="Image--1srlyyg dXORMH avatar clickable" data-category="Avatar--rg1vfe kiqBOx" alt="avatar 40x40" width="40" height="40"></div><div class="bzh-content-sidebar-cell"><h3 class="cell-title"><b>' + pointObject.name + '</b></h3><div class="cell-description">' + pointObject.description + '</div><div class="cell-adresse">Lieu : ' + pointObject.ville + '</div><div class="tags-box">' + marker.options.programme.toString().split(", ").map(programme => `<div class="tag programme" tabindex="-1"><span>${programme}</span></div>`).join('') + ' ' + marker.options.thematique.toString().split(", ").map(thematiques => `<div class="tag thematique" tabindex="-1"><span>${thematiques}</span></div>`).join('') +'</div></div>';

    listItem.addEventListener('click', function(layer) {
        showPointDetails(pointObject);

        L.marker([pointObject.lat, pointObject.lng], { icon: customIcon }).bindPopup(pointObject.name);
        var markerbzh = L.marker([pointObject.lat, pointObject.lng], { icon: customIcon }).bindPopup(pointObject.name);
        markerbzh.setIcon(L.icon({
            iconUrl: 'https://api.getwemap.com/images/pps-categories/users/575193d7b19b1c9f98f3feff.02888672.png',
            iconSize: [64, 64],
            className: 'active-marker'
        }));

        map.setView([pointObject.lat, pointObject.lng], 18);
    });

    document.getElementById('point-list').appendChild(listItem);
    if (marker) {
        marker.on('click', function() {
            showPointDetails(pointObject);
        });
    }
}
document.getElementById('clear-filters-button').addEventListener('click', function() {
    // Effacer tous les marqueurs du groupe de clusters
    markerClusterGroup.clearLayers();

    // Ajouter de nouveaux points
    for (var i = 0; i < points.length; i++) {
        var point = points[i];
        var marker = L.marker([point.lat, point.lng], {
            icon: customIcon,
            title: point.name,
            date: point.date,
            lien_fiche_projet_ou_site_web: point.lien_fiche_projet_ou_site_web,
            taux: point.taux,
            media: point.media,
            ville: point.ville,
            porteur: point.porteur,
            participation: point.participation,
            sous_titre: point.sous_titre,
            adresse: point.adresse,
            programme: point.programme,
            thematique: point.thematique,
            cout: point.cout,
            lat: point.lat,
            description: point.description,
            lng: point.lng,
            cp: point.cp,
            code_insee: point.insee,
        });

        marker.bindPopup(point.name);
        markerClusterGroup.addLayer(marker);
        addPointToListMarkerAlinit(point, marker);

        console.log(programme);
    }

    // Ajouter le groupe de clusters à la carte
    map.addLayer(markerClusterGroup);

    document.getElementById('selected-category').innerHTML = '';
    // Mettez à jour le nombre de résultats
    document.getElementById('point-count').textContent = markerClusterGroup.getLayers().length + (markerClusterGroup.getLayers().length < 2 ? ' Résultat' : ' Résultats');

    // Retirer 'selected' class et changer 'fa-close' par 'fa-plus'
    var categoryItems = document.querySelectorAll('.category-filter .Container--uno60f');
    categoryItems.forEach(function(item) {
        item.classList.remove('selected');
        var icon = item.querySelector('i.fa');
        if (icon) {
            icon.classList.remove('fa-close');
            icon.classList.add('fa-plus');
        }
    });

    // Reset selected categories
    selectedCategories = [];

});
// Fonction pour ajouter un point à la liste
function addPointToList(pointObject, marker) {
    var listItem = document.createElement('li');
    listItem.className = 'bzh-sidebar-cell';

    listItem.innerHTML = '<div class="ContentContainer--14f67fb doPXxe"><img src="https://api.getwemap.com/images/pps-categories/users/575193d7b19b1c9f98f3feff.02888672.png?width=80&amp;height=80" class="Image--1srlyyg dXORMH avatar clickable" data-category="Avatar--rg1vfe kiqBOx" alt="avatar 40x40" width="40" height="40"></div><div class="bzh-content-sidebar-cell"><h3 class="cell-title"><b>' + pointObject.name + '</b></h3><div class="cell-description">' + pointObject.description + '</div><div class="cell-adresse">Lieu : ' + pointObject.ville + '</div><div class="tags-box">' + pointObject.programme.toString().split(", ").map(programme => `<div class="tag programme" tabindex="-1"><span>${programme}</span></div>`).join('') + ' ' + pointObject.thematique.toString().split(", ").map(thematiques => `<div class="tag thematique" tabindex="-1"><span>${thematiques}</span></div>`).join('') +'</div></div>';

    listItem.addEventListener('click', function(layer) {

        L.marker([pointObject.lat, pointObject.lng], { icon: customIcon }).bindPopup(pointObject.name);
        var markerbzh = L.marker([pointObject.lat, pointObject.lng], { icon: customIcon }).bindPopup(pointObject.name);
        markerbzh.setIcon(L.icon({
            iconUrl: 'https://api.getwemap.com/images/pps-categories/users/575193d7b19b1c9f98f3feff.02888672.png',
            iconSize: [64, 64],
            className: 'active-marker'
        }));

        showPointDetails(pointObject);

        map.setView([pointObject.lat, pointObject.lng], 18);
    });

    document.getElementById('point-list').appendChild(listItem);
    if (marker) {
        marker.on('click', function() {
            showPointDetails(pointObject);
        });
    }
}
function extractVideoID(url) {
    var regExp = /^https?:\/\/(?:www\.youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    var match = url.match(regExp);

    if(match && match[1]) return match[1];

    // Pour Vimeo
    var regExp = /^https?:\/\/(?:www\.|player\.)?vimeo\.com\/(?:video\/)?(\d+)$/;
    var match = url.match(regExp);

    return (match && match[1]) ? match[1] : null;
}
// Afficher les détails du point sélectionné
function showPointDetails(point) {
    var media = "";
    console.log('premier :'+ media);
    if(point.icon){
        var name 			= point.title;
        var ville     		= point.ville;
        var lat       		= point.lat;
        var lng       		= point.lng;
        var cout      		= point.cout;
        var participation   = point.participation;
        var description     = point.description;
        var taux      		= point.taux;
        var media 			= point.media;
        var porteur 		= point.porteur;
        var sous_titre 		= point.sous_titre;
        var adresse 		= point.adresse;
        var programme 		= point.programme;
        var thematique 		= point.thematique;
        var cp 				= point.cp;
        var code_insee 		= point.code_insee;
        var date 			= point.date;
        var lien_fiche_projet_ou_site_web      = point.lien_fiche_projet_ou_site_web;
    }else{
        var name 			= point.name;
        var ville     		= point.ville;
        var lat       		= point.lat;
        var lng       		= point.lng;
        var cout      		= point.cout;
        var participation   = point.participation;
        var description     = point.description;
        var taux      		= point.taux;
        var media 			= point.media;
        var porteur 		= point.porteur;
        var sous_titre 		= point.sous_titre;
        var adresse 		= point.adresse;
        var programme 		= point.programme;
        var thematique 		= point.thematique;
        var cp 				= point.cp;
        var code_insee 		= point.code_insee;
        var date 			= point.date;
        var lien_fiche_projet_ou_site_web      = point.lien_fiche_projet_ou_site_web;
    }
    var isyoutube = /youtu/;
    var isvimeo = /vimeo/;
    console.log('last :'+ media);
    document.getElementById('header-search').style.display = 'none';
    document.getElementById('point-list').style.display = 'none';
    document.getElementById('point-details').style.display = 'block';
    document.getElementById('point-name').textContent = name;
    document.getElementById('point-description').textContent = description;
    document.getElementById('taux').textContent = point.taux;
    document.getElementById('participation').textContent = participation;
    document.getElementById('cout').textContent = cout;
    document.getElementById('porteur').textContent = porteur;
    document.getElementById('videocontainer').innerHTML = "";
    if (typeof media !== undefined && media !== null && media !== '' && isyoutube.test(media)) {

        const url = media;
        var videoID = extractVideoID(url);

        document.getElementById('videocontainer').innerHTML = '<div><iframe frameborder="0" width="100%" height="300" scrolling="no" class="media" allowfullscreen="" src="https://www.youtube.com/embed/'+videoID+'" data-dashlane-frameid="4051"></iframe></div>';
    };

    if (typeof media !== undefined && media !== null && media !== '' && isvimeo.test(media)) {

        const url = media;
        var videoID = extractVideoID(url);

        document.getElementById('videocontainer').innerHTML = '<div><iframe src="https://player.vimeo.com/video/'+videoID+'" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>';

    };
    if (typeof media === undefined){
        document.getElementById('videocontainer').remove();
    }
    document.getElementById('point-categories').innerHTML = '<div class="tags-box">' + point.programme.toString().split(", ").map(programme => `<div class="tag programme" tabindex="-1"><span>${programme}</span></span></div>`).join('') + ' ' + point.thematique.toString().split(", ").map(thematiques => `<div class="tag thematique" tabindex="-1"><span>${thematiques}</span></div>`).join('') +'</div>';
    document.getElementById('point-ville').textContent = 'Lieu : ' +point.ville;
    if(typeof point.sous_titre !== 'undefined'){
        document.getElementById('point-sous_titre').innerHTML = '<b>  <font size="3" >' +point.sous_titre+ '</font>  </b>' ;
    };
    if(point.lien_fiche_projet_ou_site_web == "" || point.lien_fiche_projet_ou_site_web === undefined){
        if( document.getElementById('lien') ){
            document.getElementById('lien').remove();
        }
    }else{
        document.getElementById('lien').href = point.lien_fiche_projet_ou_site_web;
    }

    // Afficher la flèche de retour à la liste de points
    document.getElementById('back-to-list-icon').style.display = 'block';

    document.getElementById('back-to-list-icon').addEventListener('click', function() {
        document.getElementById('point-list').style.display = 'block';
        document.getElementById('header-search').style.display = 'block';
        document.getElementById('point-details').style.display = 'none';
        document.getElementById('back-to-list-icon').style.display = 'inline-block';
    });
}

// Affichage des filtres de catégorie
var filterToggle = document.getElementById('filter-toggle');
var filterIcon = document.getElementById('filter-icon');
var categoryFilters = document.getElementById('category-filters');
var clearFiltersButton = document.getElementById('clear-filters-button');
var closeFiltersButton = document.getElementById('close-filters-button');

filterToggle.addEventListener('click', function() {
    if (categoryFilters.style.display === 'none') {
        categoryFilters.style.display = 'block';
        filterToggle.innerHTML = 'Filtres';
        clearFiltersButton.style.display = 'inline-block';
        closeFiltersButton.style.display = 'inline-block';
    } else {
        categoryFilters.style.display = 'none';
        filterToggle.innerHTML = 'Filtres';
        clearFiltersButton.style.display = 'none';
        closeFiltersButton.style.display = 'none';
    }
});


var categoryItems = document.querySelectorAll("#category-filters .clickable");
var selectedCategories = [];

function addPointToListCat(pointObject, marker) {
    var listItem = document.createElement('li');
    listItem.className = 'bzh-sidebar-cell';

    listItem.innerHTML = '<div class="ContentContainer--14f67fb doPXxe"><img src="https://api.getwemap.com/images/pps-categories/users/575193d7b19b1c9f98f3feff.02888672.png?width=80&amp;height=80" class="Image--1srlyyg dXORMH avatar clickable" data-category="Avatar--rg1vfe kiqBOx" alt="avatar 40x40" width="40" height="40"></div><div class="bzh-content-sidebar-cell"><h3 class="cell-title"><b>' + pointObject.name + '</b></h3><div class="cell-description">' + pointObject.description + '</div><div class="cell-adresse">Lieu : ' + pointObject.ville + '</div><div class="tags-box">' + pointObject.programme.toString().split(", ").map(programme => `<div class="tag programme" tabindex="-1"><span>${programme}</span></div>`).join('') + ' ' + pointObject.thematique.toString().split(", ").map(thematiques => `<div class="tag thematique" tabindex="-1"><span>${thematiques}</span></div>`).join('') +'</div></div>';

    listItem.addEventListener('click', function(layer) {
        showPointDetails(pointObject);

        L.marker([pointObject.lat, pointObject.lng], { icon: customIcon }).bindPopup(pointObject.name);
        var markerbzh = L.marker([pointObject.lat, pointObject.lng], { icon: customIcon }).bindPopup(pointObject.name);
        markerbzh.setIcon(L.icon({
            iconUrl: 'https://api.getwemap.com/images/pps-categories/users/575193d7b19b1c9f98f3feff.02888672.png',
            iconSize: [64, 64],
            className: 'active-marker'
        }));

        map.setView([pointObject.lat, pointObject.lng], 18);
    });

    document.getElementById('point-list').appendChild(listItem);
    if (marker) {
        marker.on('click', function() {
            showPointDetails(pointObject);
        });
    }
}

function filterByCategory(category) {
    // if(category === ''){
    console.log('ici');
    //updatePointList;
    // }
    markerClusterGroup.clearLayers();
    document.getElementById('point-list').innerHTML = '';

    var lowercaseCategory = category.toLowerCase();

    for (var i = 0; i < points.length; i++) {
        var point = points[i];

        if (
            point.thematique.toString().split(", ").map((thematique) => thematique.toLowerCase()).includes(lowercaseCategory) ||
            point.programme.toString().split(", ").map((programme) => programme.toLowerCase()).includes(lowercaseCategory)
        ) {
            var marker = L.marker([point.lat, point.lng], { icon: customIcon }).bindPopup(point.name);
            markerClusterGroup.addLayer(marker);
            addPointToListCat(point, marker);
        }
    }

    document.getElementById('point-count').textContent = markerClusterGroup.getLayers().length + (markerClusterGroup.getLayers().length < 2 ? ' Résultat' : ' Résultats');


    var selectedCategoriesText = selectedCategories.map(category => `<div class="Container--uno60f dvxgLQ tag selected clickable feder Tag--1u3kbo7 hKNIKT" tabindex="0">
	<span class="LabelTitle--1e0bptc fTWTpu">${category}</span></div>`).join(', ');
    document.getElementById('selected-category').innerHTML = selectedCategoriesText;
}

function removeCategory(category) {
    var index = selectedCategories.indexOf(category);
    if (index > -1) {
        selectedCategories.splice(index, 1);
    }

    filterByCategory(category);

    var selectedCategoriesText = selectedCategories.map(category => `<div class="activeCat"><span>${category}</span></div>`).join('');
    document.getElementById('selected-category').innerHTML = selectedCategoriesText;
}
categoryItems.forEach(function (item) {
    item.addEventListener("click", function () {

        var category = item.dataset.category;

        if (selectedCategories.includes(category)) {
            removeCategory(category);
            item.classList.remove('selected');
        } else {
            selectedCategories.push(category);
            item.classList.add('selected');
            filterByCategory(category);
        }

        var iconElement = item.querySelector('.IconStyled.hIdBab.fa');

        if(iconElement.classList.contains('fa-plus')) {
            iconElement.classList.remove('fa-plus');
            iconElement.classList.add('fa-close');
        } else {
            iconElement.classList.remove('fa-close');
            iconElement.classList.add('fa-plus');
        }


        var selectedCategoriesText = selectedCategories.map(category => `<div class="activeCat"><span>${category}</span></div>`).join('');
        document.getElementById('selected-category').innerHTML = selectedCategoriesText;
    });
});

document.getElementById('selected-category').addEventListener('click', function() {
    if (event.target.tagName === 'DIV') {
        var category = event.target.textContent;
        if(document.getElementById('selected-category').getElementsByTagName('div').length > 1){
            removeCategory(category);
        }else{
            if (document.getElementById('selected-category').getElementsByTagName('div').length === 1) {
                markerClusterGroup.clearLayers();
                document.getElementById('point-list').innerHTML = '';

                for (var i = 0; i < points.length; i++) {
                    var point = points[i];
                    var marker = L.marker([point.lat, point.lng], {
                        icon: customIcon,
                        title: point.name,
                        date: point.date,
                        lien_fiche_projet_ou_site_web: point.lien_fiche_projet_ou_site_web,
                        taux: point.taux,
                        media: point.media,
                        ville: point.ville,
                        porteur: point.porteur,
                        participation: point.participation,
                        sous_titre: point.sous_titre,
                        adresse: point.adresse,
                        programme: point.programme,
                        thematique: point.thematique,
                        cout: point.cout,
                        lat: point.lat,
                        description: point.description,
                        lng: point.lng,
                        cp: point.cp,
                        code_insee: point.insee,

                    });
                    marker.bindPopup(point.name);
                    markerClusterGroup.addLayer(marker);
                    addPointToListMarkerAlinit(point, marker);
                }

                // Mettez à jour le nombre de résultats
                document.getElementById('point-count').textContent = markerClusterGroup.getLayers().length + (markerClusterGroup.getLayers().length < 2 ? ' Résultat' : ' Résultats');

            }
        }
    }
});


// Fermer la liste des catégories
closeFiltersButton.addEventListener('click', function() {
    categoryFilters.style.display = 'none';
    filterToggle.innerHTML = 'Filtres';
    clearFiltersButton.style.display = 'none';
    closeFiltersButton.style.display = 'none';
});

// Affichage du nombre total de points
document.getElementById('point-count').textContent = markerClusterGroup.getLayers().length + (markerClusterGroup.getLayers().length < 2 ? ' Résultat' : ' Résultats');


// Fonction de recherche des points
document.getElementById('search-input').addEventListener('input', function() {
    const query = this.value.toLowerCase();

    clearPreviousSearchResults();

    console.log(query);

    if(query === ''){
        addPointToList(point, marker);
    }

    if (query) {
        isSearching = true;
        searchPoints(query);
    } else {
        isSearching = false;
        displayAllPoints();
    }
});

function clearPreviousSearchResults() {
    markerClusterGroup.clearLayers();
    document.getElementById('point-list').innerHTML = '';
}

function searchPoints(query) {
    points.forEach(point => {
        if (point.name.toLowerCase().includes(query) || point.ville.toLowerCase().includes(query) ) {
            addPointToMap(point);
        }
    });

    centerMapOnSearchResults();
    displaySearchResultsCount();
}

function addPointToMap(point) {
    const marker = L.marker([point.lat, point.lng], { icon: customIcon }).bindPopup(point.name);
    markerClusterGroup.addLayer(marker);
    addPointToList(point);

    marker.on('click', function() {
        showPointDetails(point);
    });

}

function centerMapOnSearchResults() {
    if (markerClusterGroup.getLayers().length > 0) {
        map.fitBounds(markerClusterGroup.getBounds());
    }
}

function displaySearchResultsCount() {
    const count = markerClusterGroup.getLayers().length;
    document.getElementById('point-count').textContent = `${count} ${count < 2 ? 'Résultat' : 'Résultats'}`;
}

function displayAllPoints() {
    console.log('ici :', points);
    points.forEach(point => {
        addPointToMap(point);
    });

    centerMapOnSearchResults();
    displaySearchResultsCount();
}

// Récupérer la liste des points depuis la balise <ul> avec l'ID "point-list"
var pointList = document.getElementById("point-list");

// Fonction pour mettre à jour la liste des points
function updatePointList(test) {
    if (isSearching) {
        return;
    }

    // Vider la liste des points
    pointList.innerHTML = "";

    // Parcourir les marqueurs visibles sur la carte
    markerClusterGroup.eachLayer(function (layer) {



        if (layer instanceof L.Marker && map.getBounds().contains(layer.getLatLng())) {
            // Récupérer les informations du marqueur
            var pointLayer = layer.options;
            var pointObeject = {name : layer.options.title, lat: layer.options.lat, lng: layer.options.lng };
            var name      		= layer.options.name;
            var ville     		= layer.options.ville;
            var lat       		= layer.options.lat;
            var lng       		= layer.options.lng;
            var cout      		= layer.options.cout;
            var participation   = layer.options.participation;
            var description     = layer.options.description;
            var taux      		= layer.options.taux;
            var media 			= layer.options.media;
            var porteur 		= layer.options.porteur;
            var sous_titre 		= layer.options.sous_titre;
            var adresse 		= layer.options.adresse;
            var programme 		= layer.options.programme;
            var thematique 		= layer.options.thematique;
            var cp 				= layer.options.cp;
            var code_insee 		= layer.options.code_insee;
            var date 			= layer.options.date;
            var lien_fiche_projet_ou_site_web      = layer.options.lien_fiche_projet_ou_site_web;

            // Créer un élément de liste pour le point
            var listItem = document.createElement("li");
            listItem.textContent = name;
            listItem.className = 'bzh-sidebar-cell';
            listItem.innerHTML = '<div class="ContentContainer--14f67fb doPXxe"><img src="https://api.getwemap.com/images/pps-categories/users/575193d7b19b1c9f98f3feff.02888672.png?width=80&amp;height=80" class="Image--1srlyyg dXORMH avatar clickable" data-category="Avatar--rg1vfe kiqBOx" alt="avatar 40x40" width="40" height="40"></div><div class="bzh-content-sidebar-cell"><h3 class="cell-title"><b>' + pointLayer.title + '</b></h3><div class="cell-description">' + pointLayer.description + '</div><div class="cell-adresse">Lieu : ' + pointLayer.ville + '</div><div class="tags-box">' + pointLayer.programme.toString().split(", ").map(programme => `<div class="tag programme" tabindex="-1"><span>${programme}</span></div>`).join('') + ' ' + pointLayer.thematique.toString().split(", ").map(thematiques => `<div class="tag thematique" tabindex="-1"><span>${thematiques}</span></span></div>`).join('') +'</div></div>';

            listItem.addEventListener('click', function(layer) {
                showPointDetails(pointLayer);

                L.marker([lat, lng], { icon: customIcon }).bindPopup(name);
                marker.setIcon(L.icon({
                    iconUrl: 'https://api.getwemap.com/images/pps-categories/users/575193d7b19b1c9f98f3feff.02888672.png',
                    iconSize: [64, 64],
                    className: 'active-marker'
                }));

                map.setView([lat, lng], 18);
            });

            // Ajouter l'élément de liste à point-list
            pointList.appendChild(listItem);

            // Ajouter un événement de clic à chaque élément de point-list
            var pointListItems = document.querySelectorAll("#point-list div");
            pointListItems.forEach(function (item) {
                item.addEventListener("click", function () {
                    var markerTitle = item.textContent;

                    // Rechercher le marqueur correspondant au titre
                    var marker = markerClusterGroup.getLayers().find(function (layer) {
                        return layer.options.title === markerTitle;
                    });

                    // Zoomer sur le marqueur
                    if (marker) {
                        map.setView(marker.getLatLng(), 13);
                    }
                });
            });

            // Ajouter un événement de clic à chaque élément de point-list
            pointListItems.forEach(function (item) {
                item.addEventListener("click", function () {
                    showPointDetails(point);
                });
            });
        }
    });
    var elementLi = (pointList.getElementsByTagName('li'));
    // On affiche le nombre de résultats
    if (elementLi.length < 2) {
        document.getElementById('point-count').textContent = elementLi.length + ' Résultat';
    }else{
        document.getElementById('point-count').textContent = elementLi.length + ' Résultats';
    };

}








// Ajouter l'événement "zoomend" à la carte pour mettre à jour point-list lors du zoom
map.on("moveend", updatePointList);

// Mettre à jour point-list au chargement initial de la page
//updatePointList();

var pointListItems = document.querySelectorAll("#point-list div");

// Ajouter un événement de clic à chaque élément de point-list
pointListItems.forEach(function (item) {
    item.addEventListener("click", function () {
        var markerTitle = item.textContent;

        // Rechercher le marqueur correspondant au titre
        var marker = markerClusterGroup.getLayers().find(function (layer) {
            return layer.options.title === markerTitle;
        });

        // Zoomer sur le marqueur
        if (marker) {
            map.setView(marker.getLatLng(), 13);
        }
    });
});
